import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const VideoCard = ({image, Title, Description, link}) => {
    const gatsbyImage = getImage(image)
    return (
        <div className="card-component">
            <Link to={link}>
                <GatsbyImage image={gatsbyImage} alt={Title} />
            </Link>
            <h3>{Title}</h3>
        </div>
    )

}

export default VideoCard;