import { graphql } from "gatsby"
import * as React from "react"
import MainLayout from "../layouts/MainLayout"
import BodyLayout from "../layouts/BodyLayout"
import VideoCard from '../components/videos/VideoCard'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Header from '../components/videos/Header'

export default function VideoList({ data, pageContext }) {
  const gatsbyImage = pageContext.trailer ? getImage(pageContext.trailer.Image.localFile) : null
  return (
    <MainLayout>
      <BodyLayout>
      <Header />
      {pageContext.trailer &&
        <div className="video-trailer">
          <Link to={`/videos${pageContext.videoBaseSlug[0]}${pageContext.trailer.Slug}`}>
            <GatsbyImage className="gatsby-image" image={gatsbyImage} alt={pageContext.trailer.Title} />
          </Link>
        </div>
      }
      <div className="video-list">
        <ul>
          {data.allStrapiVideos.edges && data.allStrapiVideos.edges.length > 0 && data.allStrapiVideos.edges.map(({ node }) => {
            return (
              <li>
                <VideoCard image={node.Image.localFile} Title={node.Title} Description={node.Description} link={`/videos${node.related_page.Slug}${node.Slug}`} />
              </li>
            )
          })}
        </ul>
      </div>
      </BodyLayout>
    </MainLayout>
  )
}


export const pageQuery = graphql`  
query($skip: Int!, $limit: Int!, $videoBaseSlug: [String!]) {
  allStrapiVideos(
    limit: $limit
    skip: $skip
    sort: {fields: related_page___created_at, order: DESC}
    filter: {related_page: {Slug: {in: $videoBaseSlug}}}
  ) {
    edges {
      node {
        Description
        Slug
        Title
        YoutubeCode
        Seo {
          Description
          Keywords
          Title
        }
        related_page{
          Slug
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
}
`