import React from "react"

const Header = ({}) => {
    return(
        <div className="page-header">
            <div className="title-icons title-icons-videos">
                <h1>Videos</h1>
            </div>
        </div>
    )
}

export default Header;